import * as React from "react"
import clsx from "clsx"
import { useTheme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import MobileStepper from "@material-ui/core/MobileStepper"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import { makeStyles } from "@material-ui/core/styles"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles(theme => ({
  arrowIcon: {
    color: theme.palette.common.white,
  },
  arrowIconDis: {
    color: theme.palette.common.grey,
  },

  arrowBox: {
    backgroundColor: "transparent",
    marginTop: "-50px",
  },

  mainGrid: {
    height: "100%",
    padding: "2rem",
  },

  innerGrid: {
    padding: "5rem  0 4rem 0",
  },

  firstBut: {
    marginRight: "1rem",
  },

  butOutlineWhite: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,

    "&:hover": {
      borderColor: theme.palette.common.seventyWhite,
    },
  },

  butSolidWhite: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },

  buttonGrid: {
    marginTop: ".5rem",
  },

  whiteText: {
    color: theme.palette.common.white,
  },

  "@global": {
    ".MuiMobileStepper-dots": {
      zIndex: "1",
    },
    "a:-webkit-any-link": {
      textDecoration: "none",
    },
    ".MuiMobileStepper-dot": {
      backgroundColor: "#f5f5f5c7 !important",
    },

    ".MuiMobileStepper-dotActive": {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  },
}))

function HeroBlock() {
  let data = useStaticQuery(graphql`
    query getSlides {
      allStrapiSlide {
        edges {
          node {
            imgPath {
              url
            }
            alignItems
            butOne
            butOneAnchorLink
            butOneExtLink
            butOneIntLink
            butOneStyle
            butOneText
            butTwo
            butTwoAnchorLink
            butTwoExtLink
            butTwoIntLink
            butTwoStyle
            butTwoText
            description
            order
            spaceLeft
            spaceLeftXs
            spaceRightXs
            spaceRight
            title
            visability
            whiteMode
            width
            widthXs
            bgPosX
            bgPosY
          }
        }
      }
    }
  `)

  let slides = []
  for (let i = 0; i < data.allStrapiSlide.edges.length; i++) {
    slides[i] = data.allStrapiSlide.edges[i].node
  }

  slides.sort((a, b) => (a.order > b.order ? 1 : -1))
  slides = slides.filter(slide => slide.visability === true)

  const theme = useTheme()
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = slides.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }
  if (slides.length > 0) {
    return (
      <Box sx={{ width: "100%" }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          interval={10000}
          enableMouseEvents
        >
          {slides.map((step, index) => (
            <div key={index} style={{ height: "100svh" }}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  sx={{
                    backgroundImage: `url(${step.imgPath.url})`,
                    backgroundPosition: `${step.bgPosX}% ${step.bgPosY}%`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    cursor: "grab",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-evenly"
                    alignItems={step.alignItems}
                    classes={{ root: classes.mainGrid }}
                  >
                    <Grid
                      item
                      xs={step.spaceLeftXs === 0 ? false : step.spaceLeftXs}
                      md={step.spaceLeft === 0 ? false : step.spaceLeft}
                    ></Grid>
                    <Grid
                      item
                      xs={step.widthXs}
                      md={step.width}
                      classes={{ root: classes.innerGrid }}
                    >
                      <Grid container direction="column">
                        <Grid item>
                          <Typography
                            variant="h3"
                            classes={{
                              root: step.whiteMode ? classes.whiteText : null,
                            }}
                          >
                            {step.title}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            classes={{
                              root: step.whiteMode ? classes.whiteText : null,
                            }}
                          >
                            {step.description}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          classes={{ root: classes.buttonGrid }}
                        >
                          <Grid item>
                            {step.butOne ? (
                              <>
                                {step.butOneAnchorLink ? (
                                  <AnchorLink
                                    to={step.butOneAnchorLink}
                                    stripHash
                                  >
                                    <Button
                                      variant={step.butOneStyle}
                                      color="primary"
                                      classes={{
                                        root: clsx(classes.firstBut, {
                                          [classes.butOutlineWhite]:
                                            step.whiteMode &&
                                            step.butOneStyle === "outlined",
                                          [classes.butSolidWhite]:
                                            step.whiteMode &&
                                            step.butOneStyle === "contained",
                                        }),
                                      }}
                                    >
                                      {step.butOneText}
                                    </Button>
                                  </AnchorLink>
                                ) : (
                                  <Button
                                    variant={step.butOneStyle}
                                    color="primary"
                                    classes={{
                                      root: clsx(classes.firstBut, {
                                        [classes.butOutlineWhite]:
                                          step.whiteMode &&
                                          step.butOneStyle === "outlined",
                                        [classes.butSolidWhite]:
                                          step.whiteMode &&
                                          step.butOneStyle === "contained",
                                      }),
                                    }}
                                    component={step.butOneIntLink ? Link : "a"}
                                    to={
                                      step.butOneIntLink
                                        ? step.butOneIntLink
                                        : null
                                    }
                                    href={
                                      step.butOneExtLink
                                        ? step.butOneExtLink
                                        : null
                                    }
                                    target={
                                      step.butOneExtLink ? "_blank" : null
                                    }
                                    rel={step.butOneExtLink ? "noopener" : null}
                                  >
                                    {step.butOneText}
                                  </Button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          <Grid item>
                            {step.butTwo ? (
                              <>
                                {step.butTwoAnchorLink ? (
                                  <AnchorLink to={step.butTwoAnchorLink}>
                                    <Button
                                      variant={step.butTwoStyle}
                                      color="primary"
                                      classes={{
                                        root: clsx({
                                          [classes.butOutlineWhite]:
                                            step.whiteMode &&
                                            step.butTwoStyle === "outlined",
                                          [classes.butSolidWhite]:
                                            step.whiteMode &&
                                            step.butTwoStyle === "contained",
                                        }),
                                      }}
                                    >
                                      {step.butTwoText}
                                    </Button>
                                  </AnchorLink>
                                ) : (
                                  <Button
                                    variant={step.butTwoStyle}
                                    color="primary"
                                    classes={{
                                      root: clsx({
                                        [classes.butOutlineWhite]:
                                          step.whiteMode &&
                                          step.butTwoStyle === "outlined",
                                        [classes.butSolidWhite]:
                                          step.whiteMode &&
                                          step.butTwoStyle === "contained",
                                      }),
                                    }}
                                    component={step.butTwoIntLink ? Link : "a"}
                                    to={
                                      step.butTwoIntLink
                                        ? step.butTwoIntLink
                                        : null
                                    }
                                    href={
                                      step.butTwoExtLink
                                        ? step.butTwoExtLink
                                        : null
                                    }
                                    target={
                                      step.butTwoExtLink ? "_blank" : null
                                    }
                                    rel={step.butTwoExtLink ? "noopener" : null}
                                  >
                                    {step.butTwoText}
                                  </Button>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={step.spaceRightXs === 0 ? false : step.spaceRightXs}
                      md={step.spaceRight === 0 ? false : step.spaceRight}
                    ></Grid>
                  </Grid>
                </Box>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          classes={{ root: classes.arrowBox }}
          nextButton={
            <Button
              size="small"
              color="secondary"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              color="secondary"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
          }
        />
      </Box>
    )
  } else {
    return <div style={{ marginTop: "100px" }}></div>
  }
}

export default HeroBlock
